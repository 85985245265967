.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-box {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 400px;
  text-align: center;
}

#modal-description {
  margin-top: 20px;
  margin-bottom: 80px; /* Adicionando mais espaço abaixo do texto */
}

.modal-button {
  width: 100%;
  background-color: #238A85;
  color: white;
}
