@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

.titulo {
    width: 100%;
    font-family: "Lato", sans-serif;
    text-align: center;
}

.telaDividida {
    display: flex;
}

.myVideo {
    height: 40%;
    width: 50%;
}


.btCronometro {
    width: 100px;
    height: 50px;
    margin: 10px;    
}

.dialogTypograhy{
    height: 600%;
    font-size: medium;
}

.textoGrande{
    border: 1px solid black;
    padding: 15px;
    width: 100%;
    min-height: 10em;
    overflow: auto;
    resize: none;
    margin-top: -0.25em;
}

.dialogText{
    font-size: large;
    margin-bottom: 10%;
}


.div3 {
    width: 95%;
    display: flex;
    justify-content: center;
}

.item2 {
    margin-top: -3%;
    justify-content: left;
}

input.radio {
    height: 30px;
}
label.vacinas{
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-left: 10%;
    font-size: 0.85em;
    margin-bottom: 1em;
}
.vacinas{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 1%;
}

div .item .btn-pdfgen{
    background: orange !important;
}

.red-percentual{
    color: red !important;
}

.green-percentual{
    color: green !important;
}