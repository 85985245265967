.modal-box {
  position: 'absolute';
  top: '50%';
  left: '50%';
  transform: 'translate(-50%, -50%)';
  width: 400px;
  background-color: white;
  border: '2px solid #000';
  box-shadow: 24;
  padding: 20px;
  outline: 0;
  overflow: scroll;
}

.modal-description {
  margin-top: 20px;
}

.modal-button {
  margin-top: 20%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.button-box{
  position:absolute;
  margin-top: 15%;
  width: 48rem;
}

.logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px; /* Ajuste para dar espaçamento entre o botão e os campos abaixo */
}

.custom-file-upload {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  line-height: 1.5;
  display: inline-block;
  padding: 8px 12px;
  cursor: pointer;
  background: green;
  transition: all 0.4s;
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  text-align: center;
  margin-left: 5px;
}

.custom-file-upload:hover {
  background: #004a29;
}

.resizeLogo{
  width: 100%;
  height: auto;
  max-width: 250px;
  max-height: 250px;
  object-fit: contain;
  padding: 8px 12px;
  border: none;
  border-radius: 25px;
}



