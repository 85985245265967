/* Mobile */
@media (max-width: 768px) {
    .div2 {
        display: grid;
    }
}

.form-wrap-solicitante{
    margin-left: 34rem;
}

.schedule-select .div2{
    width: 20em !important;
    margin-left: 34rem;
}

.schedule-select button{
    width: 20em !important;
    margin-left: 35rem;
}