.escolha-cadastro-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f7f3f8;

  h1 {
    margin-bottom: 90px;
  }

  .card-container {
    display: flex;
    gap: 40px;
  }

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    cursor: pointer;
    transition: transform 0.3s;
    width: 300px;
    height: 400px;

    &:hover {
      transform: translateY(-10px);
    }

    .image-placeholder {
      width: 280px;
      height: 280px;
      background-image: url('../../imagens/patient.png');
      background-size: cover;
      background-position: center;
      margin-bottom: 20px;
      border-radius: 5px;
    }

    .image-placeholder-doctor {
      width: 280px;
      height: 280px;
      background-image: url('../../imagens/doctor.png');
      background-size: cover;
      background-position: center;
      margin-bottom: 20px;
      border-radius: 5px;
    }

    button {
      background: #238A85;
      color: white;
      border: none;
      padding: 10px 20px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background 0.3s;

      &:hover {
        background: #185e5a;
      }
    }
  }
}
