.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f7f3f8;
}

.wrap-login100 {
  width: 50%;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 55px 37px 55px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.login100-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login100-form h1 {
  font-size: 24px;
  color: #238A85;
  margin-bottom: 30px;
}

.wrap-input100 {
  position: relative;
  width: 100%;
  border-bottom: 2px solid #d9d9d9;
  margin-bottom: 37px;
}

.input100 {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #666;
  line-height: 1.5;
  display: block;
  width: 100%;
  height: 45px;
  background: transparent;
  padding: 0 5px;
  border: none;
  outline: none;
}

.container-login100-form-btn {
  width: 100%;
  display: flex;
  justify-content: center;
}

.login100-form-btn {
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  color: #fff;
  line-height: 1.5;
  padding: 0 20px;
  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #238A85;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;
  cursor: pointer;
  border: none;
  margin-bottom: 15px;
}

.login100-form-btn:hover {
  background: #238A85;
}

@media (max-width: 768px) {
  .wrap-login100 {
    padding: 50px 25px 37px 25px;
  }
}
