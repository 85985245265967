.videoChamada {
  width: 100%;
  height: 95vh;
  border: none;
}

.videoChamada iframe {
  width: 100%;
  height: 95%;
  border: none;
}
