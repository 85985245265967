/* RecuperacaoEmail.css */
.container-recuperacao-email {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.card-recuperacao-email {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 400px;
}

.card-recuperacao-email h1 {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
}

.card-recuperacao-email .MuiButton-root {
  margin-top: 20px;
}

.card-recuperacao-email .MuiTextField-root {
  margin-bottom: 20px;
}
